<template>
  <div class="notice-list">
    <el-breadcrumb class="query-breadcrumb" separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">{{dict[language].home}}</el-breadcrumb-item>
      <el-breadcrumb-item>{{dict[language].notice}}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="notice-list-title">{{dict[language].notice}}</div>
    <div class="notice-list-warp">
      <div class="notice-list-main">
        <section v-if="pageStatus === 'detail'">
          <span v-if="!curObj.content" class="notice-list-empty">
            <div>暂无内容</div>
          </span>
          <div class="notice-list-detail" v-else>
            <div class="notice-list-detail-title">
              {{curObj.title}} {{dict[language].meetingDetails}}
            </div>
            <div v-if="computedContentIsHtml(curObj.content)">
              <div v-html="computedContent(curObj.content)"></div>
            </div>
            <div v-else>
              <ul v-for="imgUrl in computedImages(curObj.content)" :key="imgUrl">
                <li style="list-style:none">
                  <img :src="imgUrl" alt="">
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section v-else>
          <div class="query-resolve">
            <div v-for="data in dataList" :key="data.id">
              <el-row>
                <el-col :span="20">
                  <span @click="handlerClickList(data)" pointer>{{data.title}}</span>
                  <el-button type="text" v-if="data.status">[{{dict[language].top}}]</el-button>
                </el-col>
                <el-col :span="4">
                  <span class="sdf" right block>{{data.createDate}}</span>
                </el-col>
              </el-row>
              <el-divider></el-divider>
            </div>
          </div>
          <div class="query-pagination">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="dataForm.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="dataForm.limit"
              layout="total, prev, pager, next"
              :total="total"
              center
            >
            </el-pagination>
          </div>
        </section>
      </div>

      <div class="notice-list-right-nav" v-if="pageStatus === 'detail'">
        <div>
          <ul>
            <li :class="{active: data.id === curObj.id}" v-for="(data,index) in dataList" :key="data.id" @click="handlerClickList(data)">
              <el-tooltip :content="data.title" placement="top-start">
                <span>{{ index+1 }}.{{data.title}}</span>
              </el-tooltip>
            </li>
          </ul>
        </div>
        <p class="content-list-more" v-if="total > dataList.length" @click="handlermore">
          {{dict[this.language].more}}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import mixs from '@/mixs'
import mixsImagesTextParse from '@/mixs/images-text-parse'
import dict from '@/assets/json/dictionaries.json'
import {getSwNotice} from '@/http/request'
export default {
  name: "Notice",
  inject: ['language'],
  mixins: [mixs, mixsImagesTextParse],
  data() {
    return {
      pageStatus: 'list',
      dataList: [],
      curObj: {},
      dict,
      total: 0,
      dataForm: {
        limit: 10,
        page: 1
      }
    }
  },

  created() {
    if(this.$route.query.notice) {
      this.pageStatus = "detail"
    }
    this.curObj = this.$route.query.notice && JSON.parse(this.$route.query.notice) || {};
    this.$set(this.dataForm, 'lanuage', this.language === 'zh' ? 0 : 1);
    this.getSwNotice();
  },

  methods: {
    // 请求公告列表
    getSwNotice(){
      getSwNotice(this.dataForm).then(({data}) => {
        if(data.code === 0) {
          this.dataList = data.data.list;
          this.total = data.data.total;
        }
      })
    },

    computeTime({startTime, endTime}) {
      console.log(startTime, endTime)
      const st = startTime.replace(/-/g, '.').split(" ")[0];
      const et = endTime.replace(/-/g, '.').split(" ")[0];
      return `${st} - ${et}`
    },

    resetData() {
      this.$set(this.dataForm, 'id', this.id);
    },

    // 点击公告列表
    handlerClickList(obj) {
      this.curObj = obj;
      this.pageStatus = "detail";
      history.pushState(null, null, `/#notice?notice=${JSON.stringify(obj)}`)
    },

    handlermore() {
      this.pageStatus = "list"
    },

    handleCurrentChange(n) {
      this.dataForm.page = n;
      this.getSwNotice();
    }
  }
};
</script>

<style lang='less'>
.notice-list {
  width: 933px;
  margin: 0 auto;
  .query-breadcrumb {
    margin: 25px 0 70px;
  }
  .notice-list-title {
    margin-bottom: 50px;
    font-size: 31px;
    font-weight: 400;
    color: #2F6D8B;
    line-height: 20px;
  }
  .notice-list-warp {
    display: flex;
    margin-bottom: 50px;
    .notice-list-main {
      flex: 1;
      padding: 0 30px 30px 0;
      .query-pagination {
        margin: 50px 0;
      }
      
      .notice-list-empty {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #999;
      }
    }
    .notice-list-right-nav {
      width: 130px;
      // padding-left: 45px;
      box-sizing: border-box;
      ul {
        width: 100%;
        li {
          width: 100%;
          padding: 20px 0 10px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 13px;
          font-weight: 400;
          color: #595757;
          line-height: 1.5;
          position: relative;
          border-bottom: solid 1px #e6e6e6;
          cursor: pointer;
          &.active {
            font-weight: 500;
            color: rgba(20, 153, 220, 1);
            &::before {
              width: 100%;
              height: 5px;
              content: "";
              position: absolute;
              left: 0;
              bottom: 0;
              background: rgba(20, 153, 220, 1);
            }
          }
          &:first-child {
            border-top: solid 1px #e6e6e6;
          }
        }
      }

      .content-list-more {
        padding: 20px 0 10px;
        text-align: right;
        font-size: 14px;
        color: rgba(20, 153, 220, 1);
        cursor: pointer;
      }
    }
    .el-divider--horizontal {
      margin: 10px 0 50px;
    }
  }
  .notice-list-detail {
    margin-bottom: 50px;
    .notice-list-detail-title {
      margin-bottom: 40px;
      font-size: 26px;
      font-weight: bold;
      color: #123577;
      line-height: 38px;
    }
    .notice-list-detail-content{
      color: #555;
      line-height: 2;
      font-size: 14px;
      img {
        height: auto;
      }
    }
  }
  .el-button {
    color: rgb(198, 54, 54);
  }
  .el-form-item {
    margin: 0;
  }
}
</style>